import yup from "./yup";
import labels from "./labels";
import customMessagesTest from "./custom-messages-test";
import { validateCep /*, validatePIS*/ } from "validations-br";

export default {
  complete_name: yup.string().required().label(labels.complete_name),
  name_default_contact: yup
    .string()
    .required()
    .label(labels.name_default_contact),
  fantasy_name: yup.string().required().label(labels.fantasy_name),
  social_name: yup.string().label(labels.social_name),
  document_rg: yup.string().label(labels.document_rg),
  document_cpf: yup.string().cpf().required().label(labels.document_cpf),
  document_cpf_contact: yup
    .string()
    .cpf()
    .required()
    .label(labels.document_cpf),
  document_cpf_no_required: yup
    .string()
    .cpf()
    .required()
    .label(labels.document_cpf),
  document_cpf_pix: yup.string().testCPFPix().label(labels.document_cpf),
  phone_pix: yup.string().required().testPhonePix().label(labels.phone),
  document_pj: yup.string().cnpj().required().label(labels.document_pj),
  document_pj_no_required: yup
    .string()
    .testCNPJ() // Esse método valida o CNPJ, mas não verifica se já existe na plataforma
    .label(labels.document_pj),
  document_pj_pix: yup.string().testCNPJPix().label(labels.document_pj),
  document_ie: yup.string().label(labels.document_ie),
  store_name: yup.string().required().slug().label(labels.store_name),
  idExterno: yup.string().testIdExterno().label(""),
  phone: yup.string().phone().label(labels.phone).nullable(),
  celular: yup.string().celular().required().label(labels.cel),
  celular_pj: yup.string().celular().required().label(labels.cel),
  sex: yup.object().label(labels.sex).nullable(),
  ethnicity: yup.object().label(labels.ethnicity).nullable(),
  race: yup.string().label(labels.race),
  pf_nascimento: yup
    .string()
    .maxAge()
    .minAge()
    .nullable()
    .label(labels.birthdate),
  office: yup.string().label(labels.office),
  parent_name: yup.string().label(labels.parent_name),
  mother_name: yup.string().label(labels.mother_name),
  uf_birth: yup.object().label(labels.uf_birth),
  city_birth: yup.string().label(labels.city_birth),
  education: yup.object().label(labels.education).nullable(),
  type_as: yup.object().required().label(labels.type_as),

  pf_pis_pasep: yup.string().label(labels.pf_pis_pasep),
  /*
    .test("isPIS", customMessagesTest.isPIS, (value) =>
      value ? validatePIS(value) : true
    ),
    */
  zip_code: yup
    .string()
    .test("isCep", customMessagesTest.isCep, (value) => validateCep(value))
    .label(labels.zip_code),
  number: yup.string().label(labels.number),
  address: yup.string().label(labels.address),
  district: yup.string().label(labels.district),
  city: yup.string().label(labels.city),
  state: yup.string().label(labels.state),
  complement: yup.string().label(labels.complement).nullable(),
  bank: yup.object().required().label(labels.bank),
  agency: yup.string().required().label(labels.agency),
  bank_type: yup.object().required().label(labels.bank_type),
  bank_number: yup.string().required().label(labels.bank_number),
  bank_number_dv: yup.string().required().label(labels.bank_number_dv),
  facebook: yup.string().label(labels.facebook).nullable(),
  whatsapp: yup.string().label(labels.whatsapp).nullable(),
  instagram: yup.string().nullable().label(labels.instagram),
  instagram_req: yup.string().required().label(labels.instagram_req),
  youtube: yup.string().label(labels.youtube).nullable(),
  linkedin: yup.string().label(labels.linkedin).nullable(),
  tiktok: yup.string().label(labels.tiktok).nullable(),
  twitter: yup.string().label(labels.twitter).nullable(),
  site: yup.string().url().label(labels.site).nullable(),
  email_pix: yup.string().required().email().testEmailPix().label(labels.email),
  emailLogin: yup.string().required().email().label(labels.email),
  email: yup.string().required().email().emailExists().label(labels.email),
  password: yup.string().required().label(labels.password),
  passwordValidation: yup
    .string()
    .required()
    .checkPassword()
    .label(labels.password),
  code: yup.string().required().label(labels.code),
  random_key: yup.string().testRadomKey().required().label(labels.random_key),
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null])
    .label(labels.passwordConfirmation),
};
